<template>
    <client-page class="pb-0">
        <v-expansion-panels flat accordion multiple :value="[0, 1]">
            <template v-for="({ text, value }, index) in items">
                <v-expansion-panel :key="text">
                    <v-divider v-if="index > 0" />
                    <v-expansion-panel-header class="font-size-16">{{ text }}</v-expansion-panel-header>
                    <v-expansion-panel-content class="font-size-14"> {{ value || "-" }} </v-expansion-panel-content>
                </v-expansion-panel>
            </template>
        </v-expansion-panels>
        <v-divider />
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
    },
    data: () => ({
        deviceInfo: {},
    }),
    computed: {
        items() {
            return [
                { text: "User-Agent", value: window.navigator.userAgent },
                { text: "X-Requested-With", value: this.deviceInfo["x-requested-with"] },
            ];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.deviceInfo = await api.deviceInfo.get();
        },
    },
};
</script>
