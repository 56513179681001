<template>
    <client-page class="pb-0">
        <v-tabs fixed-tabs>
            <v-tab to="/privacy">개인정보처리방침</v-tab>
            <v-tab to="/EULA">최종사용자라이선스협정</v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="$route.path" style="height: calc(100vh - 56px - 48px - 1px - 48px); overflow-y: auto">
            <v-tab-item value="/privacy">
                <policy-component code="privacy" />
            </v-tab-item>
            <v-tab-item value="/EULA">
                <policy-component code="EULA" />
            </v-tab-item>
        </v-tabs-items>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PolicyComponent from "@/components/client/policies/policy-component.vue";

export default {
    components: {
        ClientPage,
        PolicyComponent,
    },
};
</script>
