var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "pb-0"
  }, [_c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "accordion": "",
      "multiple": "",
      "value": [0, 1]
    }
  }, [_vm._l(_vm.items, function (_ref, index) {
    var text = _ref.text,
        value = _ref.value;
    return [_c('v-expansion-panel', {
      key: text
    }, [index > 0 ? _c('v-divider') : _vm._e(), _c('v-expansion-panel-header', {
      staticClass: "font-size-16"
    }, [_vm._v(_vm._s(text))]), _c('v-expansion-panel-content', {
      staticClass: "font-size-14"
    }, [_vm._v(" " + _vm._s(value || "-") + " ")])], 1)];
  })], 2), _c('v-divider')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }