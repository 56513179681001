var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "pb-0"
  }, [_c('v-tabs', {
    attrs: {
      "fixed-tabs": ""
    }
  }, [_c('v-tab', {
    attrs: {
      "to": "/privacy"
    }
  }, [_vm._v("개인정보처리방침")]), _c('v-tab', {
    attrs: {
      "to": "/EULA"
    }
  }, [_vm._v("최종사용자라이선스협정")])], 1), _c('v-divider'), _c('v-tabs-items', {
    staticStyle: {
      "height": "calc(100vh - 56px - 48px - 1px - 48px)",
      "overflow-y": "auto"
    },
    model: {
      value: _vm.$route.path,
      callback: function callback($$v) {
        _vm.$set(_vm.$route, "path", $$v);
      },
      expression: "$route.path"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "/privacy"
    }
  }, [_c('policy-component', {
    attrs: {
      "code": "privacy"
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "/EULA"
    }
  }, [_c('policy-component', {
    attrs: {
      "code": "EULA"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }