<template>
    <v-sheet v-if="mode == 'text'">
        <v-card-text class="textarea" name="policies" readonly v-html="policy?.content" />
    </v-sheet>
    <v-card v-else outlined rounded="xl" elevation="0" width="100%" height="calc(100vh - 56px - 32px - 36px - 18px - 32px - 48px)" class="mx-10" style="overflow: hidden">
        <v-card-text style="height: 100%; overflow-y: auto">
            <div v-if="policy?.content">
                <p class="textarea" name="policy" readonly v-html="policy?.content"></p>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import api from "@/api";
export default {
    props: {
        code: { type: String, default: "tos" },
        mode: { type: String, default: "text" },
    },
    data: () => ({
        policy: null,
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            const { code } = this;
            const { policy } = await api.v1.policies.get({ code });
            this.policy = policy;
        },
    },
};
</script>

<style></style>
