var render = function () {
  var _vm$policy, _vm$policy2, _vm$policy3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.mode == 'text' ? _c('v-sheet', [_c('v-card-text', {
    staticClass: "textarea",
    attrs: {
      "name": "policies",
      "readonly": ""
    },
    domProps: {
      "innerHTML": _vm._s((_vm$policy = _vm.policy) === null || _vm$policy === void 0 ? void 0 : _vm$policy.content)
    }
  })], 1) : _c('v-card', {
    staticClass: "mx-10",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "outlined": "",
      "rounded": "xl",
      "elevation": "0",
      "width": "100%",
      "height": "calc(100vh - 56px - 32px - 36px - 18px - 32px - 48px)"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "height": "100%",
      "overflow-y": "auto"
    }
  }, [(_vm$policy2 = _vm.policy) !== null && _vm$policy2 !== void 0 && _vm$policy2.content ? _c('div', [_c('p', {
    staticClass: "textarea",
    attrs: {
      "name": "policy",
      "readonly": ""
    },
    domProps: {
      "innerHTML": _vm._s((_vm$policy3 = _vm.policy) === null || _vm$policy3 === void 0 ? void 0 : _vm$policy3.content)
    }
  })]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }